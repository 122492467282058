/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "TypeScript is gaining popularity among frontend devs for good reason. Prismatic Software Architect Adrian Carriger talks about how using TypeScript with React helps with handling complex client-side state and consuming third-party libraries. He also covers how adding new features, refactoring old designs and bug fixes work in our platform."), "\n", React.createElement(_components.p, null, "Watch the full talk on ", React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=8rNIT-_gLK4",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "YouTube"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
